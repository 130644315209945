// .menuMain {
//   border-top: solid 1px #e2e2e2;
//   // border-top: dotted 2.5px #cccccd;
// }
// .menuMain {
//   border-top: solid 1px #fff;
//   // border-top: dotted 2.5px #cccccd;
// }

.page-no-banner .menuMain {
  border-top: solid 1px #cccccd;
}

h2.carouselSlideHeading {
  @include heading-underline(
    $colour: white,
    $width: 50px,
    $height: 2px,
    $justify: left,
    $margin-top: 1rem,
    $margin-bottom: 1rem
  );
}

//intro
.homeIntro {
  h2 {
    @include heading-underline(
      $colour: $brand-secondary,
      $width: 50px,
      $height: 2px,
      $justify: center,
      $margin-top: 1rem,
      $margin-bottom: 1rem
    );
  }
}
.homefeaturecategory-homeboximpactstats .homeImpactWrapper > h2 {
  @include heading-underline(
    $colour: $brand-secondary,
    $width: 50px,
    $height: 2px,
    $justify: center,
    $margin-top: 1rem,
    $margin-bottom: 1rem
  );
}

.homeFeed .feedsTitle {
  @include heading-underline(
    $colour: $brand-secondary,
    $width: 50px,
    $height: 2px,
    $justify: left,
    $margin-top: 1rem,
    $margin-bottom: 1rem
  );
}
//homeboxes

.homeBox1 .homeFeatureDetailsWrapper {
  height: 100%;
}

.homeBox1 .homeFeatureDetailsWrapper h2 {
  @include heading-underline(
    $colour: white,
    $width: 50px,
    $height: 2px,
    $justify: left,
    $margin-top: 1rem,
    $margin-bottom: 1rem
  );
}

.homeBox1 [class*="DetailsWrapper"]:not(.publishDetailsWrapper),
.homeBox2 [class*="DetailsWrapper"]:not(.publishDetailsWrapper),
.homeBox3 [class*="DetailsWrapper"]:not(.publishDetailsWrapper){
  // background: rgb(13,187,185);
  // background: linear-gradient(260deg, rgba(13,187,185,1) 0%, rgba(9,122,121,1) 100%); 

  background: rgb(240,179,29);
  background: linear-gradient(260deg, rgba(240,179,29,1) 0%, rgba(228,124,29,1) 100%);

}



.homeBox1, .homeBox2, .homeBox3{

&:hover [class*="DetailsWrapper"]:not(.publishDetailsWrapper) {

 
      background: rgb(240,179,29);
      background: linear-gradient(260deg, rgba(240,179,29,1) 0%, rgba(228,124,29,1) 100%);
    

}
}
//headerContent
.headerContent .header-search {
  margin-right: 25px;
}

// JK - Have to break this grid prefab a bit early here: https://www.bugherd.com/projects/173576/tasks/22
@media (min-width: map-get($breakpoints, sm)) and (max-width: 1024px) {
  .homeFeatures {
    display: flex;
    flex-wrap: wrap;

    .homeBox1 {
      width: 100%;
    }

    .homeBox2,
    .homeBox3 {
      flex: 1;
    }
  }
}

// Test with $impact-stat-figure-colour?

.homefeaturecategory-homeboximpactstats td:first-child h2 {
  font-weight: 900;
}

.homefeaturecategory-homeboxquickgiving
  .quickGivingPanel
  .formQuestion.donationAmount
  .donationAmountFigure {
  border: solid 1px white;
  border-radius: 6px;
}
.homefeaturecategory-homeboxquickgiving{
  background: rgb(197,59,124);
  background: linear-gradient(260deg, rgba(138,115,180,1) 0%, rgba(247,148,51,1) 100%);
}
.homefeaturecategory-homeboxquickgiving h2{
  padding-right: 20px;
}
// .homefeaturecategory-homeboxquickgiving {
//   position: relative;
//   z-index: 1;

//   &::before {
//     content: "";
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     opacity: 0.8;
//     background: url(../assets/ribbon.png);
//     background-position: center;
//     background-repeat: no-repeat;
//     background-image: azure;
//     background-size: cover;
//   }
// }

.homefeaturecategory-homeboximpactstats {
  padding: 3rem 0 5rem;
}

@media (min-width: 1025px) {
  .footerBox2 {
    padding-left: 30px;
  }
}

// couldn't find this var adjustment
// JK - It's $header-search-margin-right
// .header-search button {
//   margin-right: 10px;
// }

.post .appealActionsWrapper {
  background-color: white;
  padding-left: 0;
  padding-right: 0;
}

//mailchimp styles
input#mc-embedded-subscribe {
  margin-top: 15px;
  background-color: $brand-primary;
}

#mc_embed_signup_scroll {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  input {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .homeIntro > * {
    word-break: break-word;
    font-size: 1rem;
  }

  .homeIntro h3 {
    font-size: 1.5em;
  }
}

@media (max-width: 1024px) {
  .image-align-right,
  .image-align-left {
    float: none;
    display: block;
    margin: 15px auto;

    img {
      display: block;
      float: none;
      margin: 0 auto;
    }
  }
}

// footer icons size
.pageFooter .socialIcons a:before {
  font-size: 1.5rem;
}

//impact
// .homefeaturecategory-homeboximpactstats td:last-child {
//   color: $brand-primary;
// }

@media (min-width: 577px) {
  body:not(.subsite).page-has-banner .pageHeader {
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.9),
      rgba(0, 0, 0, 0)
    );
  }
}


@media (max-width: 576px) {
  .mainLogo {
    background-image: url(../assets/logo_primary.png)!important;
  }
  .carouselControls {
    display: none;
  }
}

@media screen and (max-width: 1300px) {
  .carouselDetailWrapper {
    align-items: flex-end;
    padding-bottom: 20px;
  }
}


// Listing page - Layout
.contentBlockWrapper {
  margin-bottom: 0;
  padding-bottom: 3rem;
}

// listing page - footer
.listFooter {
  .backToTop, .loadAmount {
    display: none;
  }
}

.carouselButtons {
  padding: 2rem;
}

// Newsletter signup
.ml-field-group {
  margin-bottom: 10px;
  input {
    width: 100%;
  }
}

// .mainLogo {
//   background-size: auto 100%;
// }