// $platform-name: 'victorianassociationforthecareandresettlementofoffenders';

//
// Config values
//

$platform-name: "victorianassociationforthecareandresettlementofoffenders";
$assets-path: "../assets/";
$site-bleed: 20px;

//
// Colours
//
// $brand-primary: #077698;
// $brand-secondary: #783f7e;

$grey: #6c6d70;
$darkgrey: #616365;
$green: #005040;
$purple: #8a73b4;
$darkblue: #077597;
$lightblue: #7dbbc7;
$blue: $purple; // DES-4655
$yellow: #f0b31d;
$orange: #f79433;
$pink: #bf6293;
$brown: #4d3336;
$turquoise: #0dbbb9;
$beige: #9d8866;
// $pink: #c7387c;

$brand-primary: $blue;
$brand-secondary: $green;
$donate-colour: $pink;
// Create greyscale

$black: #000;
// Utility colours

//
// Layout
//
$border-radius: 6px;
$container-max-width: 1230px;

// Breakpoints
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1160px,
  xl: 1200px,
);

// Spacers
$spacer: 1rem;
$spacer-y: $spacer;
$spacer-x: $spacer;

//
// Misc
//
$body-colour: $grey;

//
// Transitions
//

//
// Logo
//
$logo: "logo_tagline_horizontal_white.png";
$logo-width: 273px;
$logo-height: 133px;

//
// Typography
//
$font-open-sans: "Open Sans", sans-serif;
$font-family-base: $font-open-sans;
$headings-font-family: "Roboto", serif;
$headings-colour: #58595b;
$font-weight-bold: 100;
// Links

// Icon font

//
// Buttons
//
$border-radius: 3px;
$btn-border-radius: 30px;
$btn-padding-x: 2em;
$btn-padding-y: 1em;
$btn-font-family: $headings-font-family;
$btn-font-weight: $font-weight-bold;
$btn-text-colour: #fff;
// Donate button
$donate-btn-colour: #fff;

//
// Social icons
//

$social-icons-border-radius: 100px;
$social-icons-width: 30px;
$social-icons-height: 30px;
$social-icons-font-size: 1rem;

$social-icons-background-colour: #989898;
$social-icons-colour: #fff;

// Social network colours - use these by changing $social-icons-use-brand-colours to true
$social-icons-gap: 10px;
// Header specific

// Footer specific social icons
$social-icons-footer-background-colour: transparent;

//
// Share this page
//

//
// Form elements
//

// Field groups

//
// Tables
//

//
// Card
//
$card-hover-box-shadow: 0 0 15px rgba($black, 0.25);
$card-border-radius: $border-radius;
$card-heading-colour: #58595b;
$card-hover-image-opacity: 1;
$card-hover-image-scale: 1;
$card-hover-image-opacity: 1;
$card-hover-details-background-colour: $purple;
$card-hover-heading-colour: white;
$card-border: 0;
$card-hover-summary-colour: white;
// Card text overlay

// Card text over

// Card side by side

// Card hover state

//
// Menu admin
//

// $menu-admin-enabled: false;
$menu-admin-login-link-enabled: false;
$menu-admin-manager-link-enabled: false;
$menu-admin-my-details-link-enabled: false;
$menu-admin-logout-link-enabled: false;
$basket-link-only-on-shop-pages: true;

//
// Header
//
$header-absolute-background: linear-gradient(
  180deg,
  rgba(0, 0, 0, 1) 0%,
  rgba(0, 0, 0, 0.6) 50%,
  rgba(0, 0, 0, 0) 100%
);

// Header content
$header-absolute: true;
$header-content-margin-top: $spacer;
$header-content-margin-bottom: $spacer;
$header-content-padding-top: 0;
$header-content-padding-bottom: 0;
// Tagline

// Main call to action
$cta-btn-gap: 5px;
$cta-btn-colour: white;
$cta-btn-background-colour: $orange;
// $cta-padding-right-at-nav-breakpoint: 6.5em !default; // The gap we need to leave so the cta buttons clear the burger menu button after nav breakpoint

// Search
// $header-search-input-max-width: 0;
// $header-search-input-margin-right: 0;
// $header-search-input-padding: 0px 0px;
// $header-search-input-border-colour: #fff;
$header-search-button-background-colour: $darkgrey;
// $header-search-button-icon-colour: #fff;
// $header-search-button-border-radius: 100px;
// $header-search-input-margin-right: 5px;
// $header-search-margin-right: 10px;
// $header-search-button-width: 30px;
// $header-search-button-height: 30px;

// $nav-search-input-padding: 10px;
// $nav-search-button-background-colour: #fff;
// Search - input
$header-search-input-margin-right: -46px;
$header-search-input-background-colour: rgba(#cccccc80, 0.5);
$header-search-input-placeholder-colour: white;
$header-search-input-border-width: 0; // t-r-b-l

// Search - button
$header-search-button-background-colour: transparent;
$header-search-button-icon-colour: white;
// Search - input

// Search - button

// Social icons

// Colours set above

//
// Sticky header
//

//
// Navigation
//

$nav-type: normal;
$nav-background-colour: transparent;
$nav-normal-align-items: center;

$nav-font-family: $font-open-sans;
$nav-text-transform: none;
// Top level items
$nav-top-level-item-colour: white;
$nav-top-level-item-font-weight: 600;
$nav-top-level-item-padding: 10px 15px;
// Submenus
// $nav-submenu-item-colour: #58595b;
$nav-submenu-item-colour: $brand-primary;
// Burger button

// Nav normal
$nav-normal-margin-bottom: 0;

$nav-normal-mobile-top-level-item-colour: #fff;
$nav-normal-mobile-submenu-item-colour: #fff;
$nav-normal-mobile-chevrons-colour: #fff;

//
// Specifically when no banner present and you have gone for $header-absolute: true
//

$no-banner-logo: "logo_tagline_horizontal_brown.png";
$no-banner-nav-top-level-item-colour: $grey;
$no-banner-nav-top-level-item-hover-colour: $brand-primary;
//
// Carousel
//
$carousel-max-width: 100%;
$carousel-contents-max-width: $container-max-width;
// $carousel-image-overlay: linear-gradient(
//   to right,
//   rgba(0, 0, 0, 0.7) 0%,
//   rgba(0, 0, 0, 0) 50%
// );
// $carousel-image-overlay: rgba(#000, 0.35);
$carousel-details-position-y: bottom;
$carousel-details-background-colour: transparent;
$carousel-details-box-shadow: none;
$carousel-details-max-width: 400px;
$carousel-details-margin-y: 100px;
$carousel-heading-colour: #fff;
$carousel-summary-colour: #fff;
$carousel-read-more-enabled: false;

// Carousel controls (left-right buttons)
$carousel-controls-position-y: center;
$carousel-controls-position-x: split;
$carousel-controls-button-border-radius: 0;
$carousel-controls-margin-y: 0;
$carousel-controls-margin-x: $site-bleed;
$carousel-controls-icon-colour: #fff;

// Carousel buttons (dots or tabs)
$carousel-dots-position-x: center;

// Carousel buttons dots
$carousel-dots-active-colour: white;

// Carousel buttons tabs

// Carousel after breakpoint

//
// Home intro
//
$home-intro-background-colour: white;
$home-intro-font-size: 1rem;
$home-intro-padding-y: $spacer * 1;

$home-intro-margin-top: 4rem;
//
// Home features
//
$home-features-padding-bottom: $spacer * 2;
$home-features-padding-top: $spacer * 3;
//
// Impact stats
//
$impact-stats-background-colour: transparent;
$impact-stats-padding-y: $spacer * 3;
$impact-stats-colour: $darkgrey;

// Heading
$impact-stats-heading-colour: $grey;
$impact-stats-heading-margin-bottom: 0;
// Individual stat

// Figure
$impact-stats-figure-font-family: $font-open-sans;
$impact-stats-figure-font-size: 5.5em;
$impact-stats-figure-colour: $darkgrey;
// Summary

//
// Home feeds
//
$home-feeds-background-colour: #ece9e9;
$home-feeds-padding-top: $spacer * 4;
// Feeds title
$feeds-title-colour: #333;
$feeds-title-text-align: left;
// Feed item

// Event feed items
$feed-item-event-start-date-enabled: false;
$feed-item-event-location-enabled: false;

// Twitter feed

//
// Footer
//
$footer-boxes-count: 5;
$footer-prefab: 4;
$footer-background-colour: $brown;
$footer-link-colour: white;
$footer-padding-y: $spacer * 6;
$footer-colour: white;
// Footer admin links

// Newsletter
$newsletter-heading-text-align: left;

$newsletter-background-colour: $brown;
$newsletter-button-background-colour: white;
$newsletter-button-colour: $brown;
$newsletter-colour: white;
// Newsletter hero - full width form above rest of footer

//
// Context container
//

// Donors list

//
// Posts
//

//
// Header text (container for breadcrumb and page title)
//

//
// Page title
//

//
// Sidebar
//

// Associated lists

//
// Listing
// $listing-body-background-colour: #F0F0F0;
$listing-body-background-colour: #f7f7f9;
$listing-cards-per-row-desktop: 2;
$listing-cards-per-row-tablet: 2;

// Listed post settings
$listed-appeal-totaliser-enabled: false;
//
// Breadcrumb
//

//
// Blockquote
//

//
// Fundraising
//

// Find a fundraiser widget

// Top fundraisers

//
// Donation form
//

// Donation amounts

//
// Quick giving panel
//
$home-quick-giving-background-colour: $orange;
$home-quick-giving-donate-btn-background-colour: #fff;

$home-quick-giving-padding-y: $spacer * 3;
$home-quick-giving-donation-amount-selected-figure-colour: $brown;
$home-quick-giving-heading-colour: #fff;
$home-quick-giving-donation-amount-figure-colour: #fff;
$home-quick-giving-donation-amount-background-colour: transparent;
$home-quick-giving-donate-btn-colour: $brown;
//
// Home quick giving panel
//

// Adjacent homepage quick giving

// Overlay homepage quick giving

//
// Totalisers
//
$totaliser-enabled: false;
//
// Comments
//

//
// Blog details
//

//
// FAQs
//

//
// Shop
//

// Departments list

// Product-price

// Product post

//
// Subsite
//

// Subsite nav

//
// Cookie consent
//

$banner-min-height: 0;
