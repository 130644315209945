//
// Home features
//

// Choose your overall home feature cards layout
.homeFeatures {
  @include cards-layout-prefab($number-of-cards: 3, $prefab: 13);
}

// Choose your card style for home feature cards
// .homeFeatures .homeFeature {
//   @include card;
//   @include card-basic;
// }

// Alternatively, you can target specific features rather than styling them all
// If you do this, you will need to target them ALL uniquely, so:
// .homeBox1 {...}, .homeBox2 {...}, .homeBox3 {...} etc.

.homeBox1 {
  @include card(
    $card-details-background-colour: rgba($orange, 0.85),
    $card-details-padding: $spacer * 4 $spacer * 2,
    $card-heading-colour: white,
    $card-summary-colour: white,
    $card-heading-font-size: $font-size-h4,
    $card-hover-details-background-colour: rgba($orange, 0.85),
  );
  @include card-text-overlay(
    $card-text-overlay-show-summary-on-hover: false,
    $card-text-overlay-summary-enabled: true,
    $card-text-overlay-details-max-width: 40%,
    $card-text-overlay-details-position-y: top,
    $card-text-overlay-details-position-x: right
  );
}
.homeBox2,
.homeBox3 {
  @include card(
    $card-text-align: left,
    $card-border: 0,
    $card-details-background-colour: rgba($orange, 0.85),
    $card-summary-colour: #fff,
    $card-heading-colour: #fff,
    $card-hover-details-background-colour: rgba($orange, 0.85),
    $card-hover-summary-colour: #fff,
    $card-heading-font-size: $font-size-h4
  );
  @include card-text-overlay();
}
//
// Home feeds
//

// Choose your overall home feeds layout
@include home-feeds-prefab($number-of-feeds: 2, $prefab: 2);

// Specify a card layout for each individual feed
.homeFeedBox1 .feedList {
  @include cards-layout-prefab($number-of-cards: 3, $prefab: 1);
}

.homeFeedBox2 .feedList {
  @include cards-layout-prefab($number-of-cards: 3, $prefab: 2);
}

// Choose your card style for home feed cards
.feedItem {
  @include card;
  @include card-basic;
}

//
// Listed posts
//

// Choose your card style for listed post cards
.listedPost {
  @include card;
  @include card-basic;
}
